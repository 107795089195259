<template>
  <div>
    <div class="gradient-top"></div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1 v-if="$language.current === 'en'" class="h3">Pernod Ricard Data Privacy Policy</h1>
          <h1 v-else-if="$language.current === 'ko'" class="h3">페르노리카 개인정보처리방침</h1>
          <h1 v-else class="h3">保乐力加数据隐私政策</h1>
          <CookiePolicyEN v-if="$language.current === 'en'" />
          <CookiePolicyKO v-if="$language.current === 'ko'" />
          <CookiePolicyCHT v-else />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col v-if="$language.current === 'ko'" cols="12">
          <h2 class="h3">쿠키 관리 </h2>
          <p>고객은 또한 “추적 금지” 기능을 활성화시킬 수 있으며 이 기능은 최신 웹 브라우저 대부분이 갖추고 있습니다. </p>
          <p>“제 3자(서드 파티)” 쿠키를 차단해도 페르노리카 홈페이지를 살펴보는 데에는 아무런 지장이 없습니다. </p>
          <h5><u><strong>쿠키 선택해제와 활성화</strong></u></h5>
          <p>이 기능을 통해 사용자는 최초 배너를 통해 일괄적으로 설정되었던 홈페이지의 쿠키 설정을 변경할 수 있습니다. 설정을 변경한 후에는 “저장” 버튼을 눌러 새로운 환경설정을 저장해야 합니다.</p>
          <h5><u><strong>기술 쿠키</strong></u></h5>
          <p>기술 쿠키는 페르노리카의 홈페이지를 사용하는 데 필요한 쿠키로, 기술 쿠키를 차단할 경우 홈페이지가 올바르게 기능하지 못하게 됩니다. 기술 쿠키는 고객의 동의 없이 설치됩니다. </p>
          <h5><u><strong>쿠키 분석</strong></u></h5>
          <p>사용자의 검색 활동에 대한 정보를 집합체의 형태로 수집하여 사용자의 검색 경험과 서비스 자체를 최적화합니다. 쿠키 분석을 차단할 경우 사용자의 검색 경험 최적화는 이루어지지 않게 되나, 홈페이지 자체는 여전히 검색하고 사용할 수 있는 상태를 유지합니다.</p>
          <div class="buttons">
            <button-text @click="onSave('false')" type="button" :decoration="false" :disabled="analytics === 'false' || !analytics" :class="{active: analytics === 'false' || !analytics}" class="disagree">거부</button-text>
            <button-text @click="onSave('true')" type="button" :decoration="false" :disabled="analytics === 'true' || analytics !== 'false'" :class="{active: analytics === 'true' || analytics !== 'false'}" class="agree">허용</button-text>
          </div>
        </v-col>
        <v-col v-else cols="12">
          <h2 class="h3">Manage Cookie</h2>
          <p>In addition, you can activate the "Do Not Track" option present in most of the latest generation browsers.</p>
          <p>Disabling "third party" cookies does not affect the navigability of this site in any way.</p>
          <h5> <u> <strong> Deselection and activation of cookies </strong> </u> </h5>
          <p> This section allows the User to change the cookie setting of the site previously configured by the same through the initial banner. Once the settings have been changed, it is necessary to click on the SAVE button to save the new configuration. </p>
          <h5> <u> <strong> Technical cookies </strong> </u> </h5>
          <p> Necessary for the use of the website, blocking them does not allow them to function. Your consent is not required for their installation. </p>
          <h5> <u> <strong> Cookies analytics </strong> </u> </h5>
          <p> They allow you to collect information in aggregate form on users' browsing to optimize the browsing experience and the services themselves. Blocking them would not allow the Owner to optimize the user's browsing experience, but the website would still be navigable. </p>
          <div class="buttons">
            <button-text @click="onSave('false')" type="button" :decoration="false" :disabled="analytics === 'false' || !analytics" :class="{active: analytics === 'false' || !analytics}" class="disagree">Disabled</button-text>
            <button-text @click="onSave('true')" type="button" :decoration="false" :disabled="analytics === 'true' || analytics !== 'false'" :class="{active: analytics === 'true' || analytics !== 'false'}" class="agree">Enabled</button-text>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ButtonText from '@/atoms/ButtonText.vue'
import CookiePolicyEN from '@/molecules/CookiePolicyEN.vue'
import CookiePolicyKO from '@/molecules/CookiePolicyKO.vue'
import CookiePolicyCHT from '@/molecules/CookiePolicyCHT.vue'

const getOption = (name, defaultValue) => localStorage.getItem(name) === null ? defaultValue : localStorage.getItem(name)

export default {
  name: 'CookiePage',
  components: { ButtonText, CookiePolicyEN, CookiePolicyKO, CookiePolicyCHT },
  data () {
    return {
      analytics: getOption('analytics-consent') || null
    }
  },
  async mounted () {
    await this.$nextTick()
    this.$events.$emit('stop-audio')
    this.trackPageView()
  },
  methods: {
    onSave (state) {
      this.analytics = state
      localStorage.setItem('analytics-consent', this.analytics)
      this.$root.toggleAnalytics()
    },
    trackPageView () {
      if (this.$root.useAnalytics && this.$gtag) {
        this.$gtag.pageview({
          page_title: 'Cookie | Royal Salute',
          page_path: window.location.pathname,
          page_location: window.location.href,
          send_page_view: false
        })
      }
    }
  },
  watch: {
    '$root.useAnalytics' (newValue, oldValue) {
      if (!oldValue && newValue) {
        this.trackPageView()
      }
    }
  },
  metaInfo () {
    let title = ''
    if (this.$language.current === 'en') title = 'Pernod Ricard Data Privacy Policy'
    else if (this.$language.current === 'ko') title = '페르노리카 개인정보처리방침'
    else title = '保乐力加数据隐私政策'
    return {
      title
    }
  }
}
</script>

<style lang="scss" scoped>
.gradient-top {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: $z-gradient;
  height: 200px;
  pointer-events: none;
  opacity: 0.75;
  background: linear-gradient(0deg, $c-blue-0 0%, $c-blue 50%);

  @include breakpoint ('sm-and-down') {
    position: fixed;
  }
}

.container {
  padding-bottom: 100px;

  @include breakpoint ('sm-and-down') {
    padding-top: 150px;
  }
}

h1 {
  margin: 32px auto;
}

h5 {
  margin-top: 32px;
  margin-bottom: 16px;
}

p {
  margin-bottom: 16px !important;
}

a {
  color: $c-gold;
}

ul {
  margin: 0 16px 16px;

  list-style: disc;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}

::v-deep .button-text.active .light {
  width: 100%;
}

.disagree {
  margin-right: 80px;

  &:disabled {
    pointer-events: none !important;
  }

  ::v-deep {
    .light {
      color: $c-red;
    }

    .dark {
      color: $c-red;
      opacity: 0.6;
    }
  }
}

.agree {
  &:disabled {
    pointer-events: none !important;
  }

  ::v-deep {
    .light {
      color: $c-gold;
    }

    .dark {
      color: $c-gold;
      opacity: 0.6;
    }
  }
}
</style>
