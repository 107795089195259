<template>
  <div>
    <h5>I. 全球政策设立原</h5>
    <p>保乐力加股份有限公司（法国法律框架下正式组织的股份有限公司，注册地址12 place des Etats-Unis 75783 Paris Cedex 16）及其附属子公司 （共称“保乐力加”）对员工、客户、供应商、消费者及包括未来员工和前员工的其他个人的个人数据予以定义。</p>
    <p>上述个人数据处理历来对保乐力加至关重要，但随时保障我们所处理个人数据的保密性及安全性却仍愈加必要，原因如下：</p>
    <p>(i) 所有保乐力加活动采用不断演进的新技术，以期 (a) 管理集团员工并帮助员工更好地交流 (b) 管理顾客、消费者及供应商并落实各项计划， 为顾客及产品消费者提供更好的服务；</p>
    <p>(ii) 保乐力加业务所在世界各地隐私权保护立法的发展。</p>
    <p>个人数据滥用或安全漏洞除了带来法律风险之外，还可能对品牌及企业形象造成严重损害，随即对保乐力加员工、顾客及消费者带来影响。</p>
    <p>本保乐力加数据隐私政策（“全球政策”）包含了保乐力加在数据隐私方面设立的最低标准，以确保我们以公平、透明及安全的方式收集、使用、保存及披露个人数据。</p>
    <p>本政策与适用法律法规主要要求保持一致，某些情况下甚至更为严格。此外，保乐力加集团各单位执行保乐力加信息及个人数据收集使用工作相 关专项政策，以覆盖日常活动所需具体个人数据处理工作的需要。 全球政策亦与上述具体政策保持一致。</p>
    <p>本政策承认很多保乐力加附属企业所在国家的隐私及数据保护法律或文化各有不同。本全球政策允许在当地法律框架下根据需要作出相应调整。</p>
    <p>若本全球政策与当地适用隐私政策和/或适用当地相关法律发生冲突，或本全球政策规定不适用，以当地适用政策和当地法律为准。</p>
    <p>本全球政策文件末尾处提供了一些有用定义，以供参考。</p>
    <h5>II. 全球政策适用对象</h5>
    <ul>
      <li>在其业务活动中有权接触到以保乐力加名义所处理的个人数据的所有保乐力加员工及临时工作人员（包括合同工）。</li>
      <li>负责确保其业务职能范围内的合理隐私管控工作正常执行的经理人员。</li>
      <li>负责确保项目初期评估隐私规定（必要时纳入流程、体系及服务机制）的项目及计划发起人。</li>
      <li>为保乐力加提供服务或代其提供服务的，需遵循与全球政策原则一致的行为标准的第三方。</li>
    </ul>
    <h5>III. 政策适用范围</h5>
    <p>除非当地政策另有规定，本全球政策适用于所有个人数据，含各类数据（印刷或数字版）及各种用于处理个人数据的媒介、设备及工具。</p>
    <p>各国对“个人数据”的定义有所不同，但一般情况下，“个人数据”意即任何已识别或可识别的自然人（“数据主体”）相关的信息。该自然人 是可被直接或间接识别的个体，特别是通过诸如姓名、识别号码、定位数据、网上标识，或与该自然人的身体、生理、遗传、心理、经济、文化 或社会身份有关的一个或多个因素。虽然所有个人数据都很重要，某些数据被视为“敏感数据”。敏感数据属于个人数据的子集，根据其性质被法律或适用政策规定为需要附 加隐私安全保护的数据。</p>
    <p>敏感数据的处理工作须遵循严格法律规定，在无专门保乐力加政策或当地适用法律许可的情况下不得予以处理。</p>
    <p>我们若得知或意识到我们在处理未成年人个人数据，相关数据将被立即删除。</p>
    <h5>IV. 管理保乐力加个人数据处理工作的七大原则</h5>
    <p>保乐力加重视交付于其的个人数据，将根据以下原则要求进行数据收集、使用、保留和披露工作。</p>
    <p>1. 合法、公平、透明</p>
    <p>我们使用个人数据的前提条件：</p>
    <ul>
      <li>履行与数据主体签订的合同所需；</li>
      <li>遵守法律业务所要求；</li>
      <li>根据合理业务需要或合理业务理由在我们的业务活动框架下需使用个人数据；</li>
      <li>在专门要求情况下获取数据主体的同意。例如根据法律或适用政策要求，保乐力加可能需要获取数据主体的同意，以便收集、使用、保留和披露个人数据。这种情况也有可能在前文所述其它理由不存在时出现。</li>
    </ul>
    <p>我们认为有必要在收集、使用、保留或披露个人数据之前对隐私风险予以评估（例如使用新体系或执行项目时）。</p>
    <p>保乐力加仅以其隐私说明或隐私政策文件所描述的方式，并与在数据主体处所获取的许可一致的前提下，对个人数据进行处理。</p>
    <p>在法律要求的情况下，我们将确保向数据主体提供有关其个人数据处理工作的相关信息。该信息将会包括数据处理目的、接收方类别、数据主体 可行使的权利清单等。在有相关要求的国家，我们将通知数据主体或获取预先批准。</p>
    <p>2. 具体及合法的目的、数据最小化、准确度</p>
    <p>收集个人数据必须具有特定、清晰且合理的目的（可有多重目的），相关数据将不会以违背上述目的的方式进行再次处理。</p>
    <p>我们在启动项目（例如人力资源数据管理、招聘数据管理、工资单、账户创建、新闻通讯订阅、组织活动等）之前先谨慎地评估并确定个人数据处 理工作的目的。</p>
    <p>我们将确保所收集的个人数据针对数据处理目的及其最终用途（洞察、营销、促销推广等）具有相关性、合理性且不过度。</p>
    <p>收集敏感数据时尤须注意其合理性。例如，非商业网站无需要求提供信用卡信息，但登录与酒精有关的网站前要求提供年龄与位置不仅恰当 合理，更是必不可少。</p>
    <p>集团将采取一切必要措施，保证在个人数据处理的各项环节（例如收集、传输、存储、检索），个人数据都处于合理、准确且更新的状态。</p>
    <p>3.安全与保密</p>
    <p>我们遵守个人数据相关使用、技术及组织层面的政策、标准与流程，以保护为支持我们的业务活动而收集、使用、保留和披露的个人数据。</p>
    <p>集团在技术及组织方面执行业界标准措施，避免无意或非法的破坏、丢失、更改、未经允许的披露或获取信息，以及任何其它非法或未经允 许的数据处理行为。</p>
    <p>保乐力加计划采取基于隐私设计、默认隐私原则的合理措施，以在处理个人数据时进行必要的保护。</p>
    <p>若个人数据处理可能对数据主体的权利与自由带来高度风险且法律上存在相关要求，我们将在落实工作之前进行隐私影响评估。</p>
    <p>对任何违反行为（无论程度如何）都需采取行动。我们将对所有涉及适用数据保护法律违反行为的报告予以审议，并将采取一切必要措施以控制 其影响。</p>
    <p>4. 个人数据保留</p>
    <p>我们根据具体业务活动或法律监管要求的具体需要对个人数据保留时间予以限制。</p>
    <p>个人数据保留与销毁的方式与相关适用法律及适用保乐力加数据保留政策保持一致。</p>
    <p>5.数据主体的权利</p>
    <p>我们对数据主体提出的关于其个人数据的问询或要求予以回应。我们根据法律要求向数据主体提供获取、纠正、限制和删除其个人数据的 可能性。我们也允许他们根据适用法律对其个人数据的处理提出异议并行使其数据可携权。</p>
    <p>6.对第三方披露</p>
    <p>保乐力加保证仅在业务需要、法律要求（包括法院判决要求）时让第三方获取或向其传输个人数据，并确保恰当的合同保护或法律担保得以 执行。</p>
    <p>保乐力加确保第三方或供应商获取保乐力加个人数据时遵守经保乐力加批准的合同相关规定。</p>
    <p>保乐力加在法律规定及法律程序要求下或为保护保乐力加的利益、权利及财产可向监管部门、司法部门及政府部门披露个人信息。</p>
    <p>7.国际传输</p>
    <p>我们仅在使用相关适用数据保护法律所规定的必要传输机制的前提下进行欧洲经济区国家与非欧洲经济区国家之间的个人数据传输，以保障 所传输数据受到恰当保护。</p>
    <p>我们根据法律要求在传输前通报相关隐私监管部门或获取预先批准。</p>
    <h5>我们根据法律要求在传输前通报相关隐私监管部门或获取预先批准。</h5>
    <p>数据主体将被告知其可通过邮件形式（groupdpo@pernod-ricard.com ）就隐私权问题进行投诉，也可向监管部门进行投诉。上述信息尤其将 在提供给数据主体和/或数据主体可获取的隐私说明文件中予以详述。</p>
    <p>若对本全球政策及其执行工作存有疑问，请联系集团数据保护官员 groupdpo@pernod-ricard.com 。</p>
    <p>鉴于集团业务与监管环境随时发生变化，本全球政策也可能发生演变。</p>
    <p>本全球政策于2019年3月14日出台并生效。</p>
    <h5 class="text-center">有用的定义</h5>
    <p>程序：</p>
    <p>保乐力加为其负责个人数据处理的工作人员所提供实用指南，使其遵守本全球政策相关规定要求，确保个人数据的保护。</p>
    <p>处理：</p>
    <p>处理指对个人数据的各种处理，覆盖了与个人数据有关的使用不同媒介的各种操作，尤其是对数据的采集、记录、组织、重组、存储、调 整、变换、检索、使用、传输查询、传输披露、散播、提供、对齐或组合、限制、删除或销毁等，既包含自动处理（计算机处理）又涉及 人工处理（纸版文件）。</p>
    <p>数据处理者：</p>
    <p>数据处理者指代表数据控制者处理个人数据的人。</p>
    <p>数据控制者：</p>
    <p>数据控制者指单独或与他人共同确定个人数据处理的目的（该实体为何需要处理数据）和方式（软件管理的数据库、纸质文件、集中式数据库）的实体。</p>
    <p>数据主体：</p>
    <p>数据主体是被识别的或可根据所收集个人数据予以识别的自然人（例如保乐力加顾客、保乐力加顾客或合同商、保乐力加消费者）。</p>
  </div>
</template>

<script>
export default {
  name: 'CookieCHT'
}
</script>

<style lang="scss" scoped>
h5 {
  margin-top: 32px;
  margin-bottom: 16px;
}

p {
  margin-bottom: 16px !important;
}

a {
  color: $c-gold;
}

ul,
ol {
  margin: 0 16px 16px;
}

ul {
  list-style-type: disc;
}
</style>
