import { render, staticRenderFns } from "./CookiePolicyEN.vue?vue&type=template&id=43d0afb6&scoped=true&"
import script from "./CookiePolicyEN.vue?vue&type=script&lang=js&"
export * from "./CookiePolicyEN.vue?vue&type=script&lang=js&"
import style0 from "./CookiePolicyEN.vue?vue&type=style&index=0&id=43d0afb6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43d0afb6",
  null
  
)

export default component.exports